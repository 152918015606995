<template>
  <div class="examine_container">
    <div class="header">
      <div class="picker">
        <van-field readonly clickable label="选择体检人" :value="patName" @click="patNamePickerShow = true"
          right-icon="arrow-down" />
        <van-popup v-model="patNamePickerShow" round position="bottom">
          <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
        </van-popup>
      </div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">温馨提示：默认查询7日内的预约记录</van-notice-bar>
      <div class="picker">
        <van-field readonly clickable label="选择预约日期" :value="date" @click="datePickerShow = true" right-icon="arrow-down"
          class="picker" />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
            :formatter="formatter" @confirm="dateConfirm" @cancel="dateCancel" />
        </van-popup>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="(item, index) in 10" :key="index">
        <div>
          <div class="top">
            <div class="info">
              <div>体检套餐：<span>驾驶员体检</span></div>
              <div>体检人：<span>刘伟杰</span></div>
              <div>体检时间：<span>2021-04-21</span></div>
              <div>流水号：<span>123456</span></div>
              <van-tag type="success" plain size="large">已&nbsp;预&nbsp;约</van-tag>
            </div>
          </div>
          <div class="bottom">
            <div class="cost">体检费：30.00元</div>
            <div class="btn">
              <!-- 释放号源 -->
              <van-button type="info">取&nbsp;消</van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Register",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      medicalCardNo: "",
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: ""
    };
  },
  created () {
    this.medicalCardNo = this.$route.query.medicalCardNo;
    this.date = this.formatTime(-6);
    this.end = this.formatTime(0);
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        if (this.medicalCardNo === undefined) {
          this.patNameIndex = 0;
        } else {
          this.patNameIndex = this.patNameArray.findIndex(item => {
            return item.pat_card_no === this.medicalCardNo;
          });
        }
        this.patName = this.patNameList[this.patNameIndex];
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getOrderList();
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm (value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getOrderList();
    },
    dateCancel () {
      this.datePickerShow = false;
    }
  }
};
</script>

<style scope>
.examine_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.examine_container .header .picker .van-cell {
  padding: 30px;
}

.examine_container .header .picker .van-cell::after {
  border: 0;
}

.examine_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.examine_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.examine_container .header .picker .van-icon {
  font-size: 40px;
}

.examine_container .header .picker .van-picker-column {
  font-size: 40px;
}

.examine_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.examine_container .header .picker .van-picker__cancel,
.examine_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.examine_container .header .van-notice-bar__content {
  font-size: 32px;
}

.examine_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.examine_container .list {
  padding-bottom: 60px;
  margin-bottom: 0;
}

.examine_container .list .item {
  border-bottom: 10px solid #f5f5f5;
}

.examine_container .list .item .top {
  position: relative;
  font-size: 40px;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 2px dotted rgb(201, 191, 191);
  line-height: 70px;
}

.examine_container .list .item .top span {
  color: #1989fa;
}

.examine_container .list .item .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
}

.examine_container .list .item .bottom .cost {
  font-size: 40px;
}

.examine_container .list .item .bottom .btn {
  display: flex;
  align-items: center;
}

.examine_container .list .item .bottom .btn .van-button {
  font-size: 34px;
}

.examine_container .list .item .top .van-tag {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  font-size: 40px;
  padding: 20px;
}
</style>

